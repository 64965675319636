<template>
    <div>
        <img style="width: 100%" src="@/assets/sino-business-license.jpg" alt="">
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>